// import packages
import React, {useState} from "react";
import {connect} from 'react-redux';
import isEmail from "validator/es/lib/isEmail";

//Import assets
import './conatctBloock.scss';
import {CallIcon,LocationIcon,SMSIcon} from "../../assets/images";
import {LoadingOutlined} from "@ant-design/icons";

//Import Components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail} from "../../redux/actions";
import {InputGroup} from "../uiElements/inputGroup";


function ContactBlock(props) {
    const {staticTexts,requestLoading} = props
    const [fieldsData,setFieldsData] = useState({
        firstName:'',
        phone:'',
    })
    const [error,setError] = useState({
        firstName:false,
        phone:false,
    })

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && fieldsData.phone.length < 5 )){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function sendVisit() {
        if(validate()){
            props.SendEmail(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName:'',
                    phone:'',
                })
            })
        }
    }
    

    function getInputValues(e) {
        const {name, value} = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    return <div className={'bottom-block'}>
        <div className={'inputs-subTitle'}>
            {staticTexts?.contact_subtitle}
        </div>
        <div className={'inputs-title'}>
            {staticTexts?.contact_title}
        </div>
        <div className={'inputs-description'}>
            {staticTexts?.contact_description}
        </div>
        <div className={'name-inputs'}>
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={fieldsData.firstName}
                error={error.firstName}
                name={'firstName'}
                placeholder={staticTexts?.contacts_page_placeholder_name}
                label={staticTexts?.contacts_page_label_name}
                maxLength={100}
                onChange={getInputValues}
            />
            <InputGroup
                inputType={'input'}
                type={"text"}
                value={fieldsData.phone}
                error={error.phone}
                name={'phone'}
                placeholder={staticTexts?.contacts_page_label_phoneNumber}
                label={staticTexts?.contacts_page_placeholder_phoneNumber}
                maxLength={100}
                onChange={getInputValues}
            />
            {/*<InputGroup*/}
            {/*    inputType={'input'}*/}
            {/*    type={"text"}*/}
            {/*    value={fieldsData.lastName}*/}
            {/*    error={error.lastName}*/}
            {/*    name={'lastName'}*/}
            {/*    placeholder={staticTexts?.contacts_page_placeholder_lastName}*/}
            {/*    label={staticTexts?.contacts_page_label_lastName}*/}
            {/*    maxLength={100}*/}
            {/*    onChange={getInputValues}*/}
            {/*/>*/}
        </div>
        {/*<InputGroup*/}
        {/*    inputType={'input'}*/}
        {/*    type={"text"}*/}
        {/*    value={fieldsData.email}*/}
        {/*    error={error.email}*/}
        {/*    name={'email'}*/}
        {/*    placeholder={staticTexts?.contacts_page_placeholder_email}*/}
        {/*    label={staticTexts?.contacts_page_label_email}*/}
        {/*    maxLength={100}*/}
        {/*    onChange={getInputValues}*/}
        {/*/>*/}

        {/*<InputGroup*/}
        {/*    inputType={'textarea'}*/}
        {/*    type={"text"}*/}
        {/*    value={fieldsData.message}*/}
        {/*    error={error.message}*/}
        {/*    name={'message'}*/}
        {/*    placeholder={staticTexts?.contacts_page_placeholder_message}*/}
        {/*    label={staticTexts?.contacts_page_label_message}*/}
        {/*    onChange={getInputValues}*/}
        {/*/>*/}
        <button className='sent-email'
                onClick={() => {
                    if (!requestLoading && sendVisit) {
                        sendVisit()
                    }
                }}>
            {staticTexts?.contacts_page_btn_send}
            {
                requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                    : ''
            }

        </button>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactBlock);

